<template>
    <div>
        <div class="toolBar mBottom10">
            <el-cascader v-show="global.isAdmin" :props="global.orgTreeProps" :options="global.orgList" :show-all-levels="false" v-model="global.orgId" @change="handleChange"
                         size='small' style="margin-right: 20px"></el-cascader>
            <el-button type="primary" size='small' plain @click="showSearch">查询</el-button>
            <el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
        </div>

        <el-card class="box-card">
            <el-table :data="recordList" size="small" class="maxWidth" stripe :height='tableHeight'>
                <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                <el-table-column prop="patientName" label="姓名" align="center" width="100"></el-table-column>
                <el-table-column prop="phone" label="手机号" align="center" ></el-table-column>
                <el-table-column prop="registeredDate"  label="预约时间" align="center"></el-table-column>
                <el-table-column prop="registeredNumber" width="100" label="号源" align="center"></el-table-column>
                <el-table-column prop="depName" label="挂号科室" align="center"></el-table-column>
                <el-table-column prop="doctorName" label="挂号医生" align="center" ></el-table-column>
                <el-table-column prop="createTime" label="挂号时间" align="center"></el-table-column>
                <el-table-column prop="registeredStatus" label="状态" align="center"  :formatter="formatterStatus"></el-table-column>
<!--                <el-table-column label="操作" align="center">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button type='text'-->
<!--                                   @click="handleView(scope.row)">查看就诊记录-->
<!--                        </el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
            </el-table>
        </el-card>

        <div class="toolBar clearfix" style="margin-top: 10px">
            <pagi-nation :page='page' @sizeChange='sizeChange' @currentChange='currentChange'></pagi-nation>
        </div>

        <!-- 查询角色 -->
        <el-dialog title="查询" v-dialogDrag :visible.sync="isShowSearchRecordDlg" width="40%">
            <el-form :model="searchForm" :rules="rules" size='small' label-width="100px">
                <el-row>
                    <el-col :sm="24">
                        <el-form-item label="角色名称">
                            <el-input v-model="searchForm.name" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="角色关键字">
                            <el-input v-model="searchForm.keyWord" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
					<el-button @click="isShowSearchRecordDlg = false" size='small'>取消</el-button>
					<el-button type="primary" @click="searchRecord" size='small'>确定</el-button>
				  </span>
        </el-dialog>

        <!-- 查询 -->
        <el-dialog title="查询" v-dialogDrag :visible.sync="isShowSearchRecordDlg" width="40%">
            <el-form :model="searchForm" size='small' label-width="100px">
                <el-row>
                    <el-col :sm="24">
                        <el-form-item label="姓名：">
                            <el-input v-model="searchForm.name" clearable placeholder="患者姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="手机号：">
                            <el-input :maxlength=11 v-model="searchForm.phone" clearable placeholder="患者手机号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="挂号科室：">
                            <el-select v-model="searchForm.epartmentId" clearable placeholder="请选择" class="w100">
                                <el-option
                                        v-for="item in registeredDepart"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="挂号状态：">
                            <el-select v-model="searchForm.registeredStatus" clearable placeholder="请选择" class="w100">
                                <el-option
                                        v-for="item in global.DictionaryData['registeredStatus']"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="是否就诊：">
                            <el-select v-model="searchForm.confirmState" placeholder="请选择" class='maxWidth'
                                       size='small'>
                                <el-option v-for="item in global.DictionaryData['yesOrNo']" :key="item.value"
                                           :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label='时间'>
                            <el-date-picker style="width: 100%" value-format="yyyy-MM-dd" :clearable="false"
                                            @change='dataChange' v-model="dateValue" type="daterange" align="right"
                                            unlink-panels range-separator="至" start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isShowSearchRecordDlg = false" size='small'>取消</el-button>
                <el-button type="primary" @click="getRecordList" size='small'>确定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="就诊数据查询" :visible.sync="isShowDetailDlg" width="80%">
            <el-table :data="medicalRecord" size="small" class="maxWidth" stripe>
                <el-table-column type="index" width="50" label="序号" align="center"></el-table-column>
                <el-table-column prop="type" :formatter="formatMedicalType" label="类型" width="100" align="center"></el-table-column>
                <el-table-column prop="medicalNumber" label="就诊序号"  width="100" align="center"></el-table-column>
                <el-table-column prop="registeredTime" label="挂号时间"  align="center"></el-table-column>
                <el-table-column prop="beginTime" label="就诊开始时间"  align="center"></el-table-column>
                <el-table-column prop="endTime" label="就诊结束时间"  align="center"></el-table-column>
                <el-table-column prop="departmentName" label="就诊科室"  align="center"></el-table-column>
                <el-table-column prop="doctorName" label="医生" width="100" align="center"></el-table-column>
                <el-table-column prop="diagnosis" label="诊断" align="center"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isShowDetailDlg = false" size='small'>取消</el-button>
                <el-button type="primary" @click="confirmVisit" size='small'>确认就诊</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    class Record {
        constructor() {
            this.patientName = null;
            this.phone = null;
            this.departmentId = null;
            this.registeredStatus = null;
            this.confirmState = null;
            this.beginDate = null;
            this.endDate = null;
            this.sourceType = 0;
        }

        reset() {
            this.constructor();
        }
    }

    export default {
        name: 'permissionConfig',
        data() {
            return {
                recordList: [],
                tableHeight: document.documentElement.clientHeight - 288,
                page: new this.Page(),

                isShowSearchRecordDlg: false,
                searchForm: new Record(),

                rules: {
                    name: [{required: true, message: '角色名称是必须的', trigger: 'blur'}],
                    keyWord: [{required: true, message: '关键字是必须的，且不可重复', trigger: 'blur'}],
                },

                registeredDepart: [],//预约科室

                dateValue: [],

                isShowDetailDlg: false,
                medicalRecord: []
            }
        },
        computed: {
        },
        methods: {
            formatterStatus(row, column) {
                return this.getname('registeredStatus', row.registeredStatus);
            },
            formatMedicalType(row,column){
                return this.getname('medicalType', row.type);
            },
            dataChange(val) {
                if (!val) {
                    this.dateValue = [];
                }
                this.searchForm.beginDate = this.dateValue[0];
                this.searchForm.endDate = this.dateValue[1];
            },
            showSearch() {
                if (!this.global.orgId.length) {
                    this.$message.warning('请先选择机构');
                    return;
                }
                this.isShowSearchRecordDlg = true;
            },
            handleChange(val) {
                if (val) {
                    localStorage.setItem('orgId',JSON.stringify(val));
                    this.getRecordList();
                    this.getDepartment();
                }
            },
            handleDetail(row) {
                this.$router.push({name: 'roleDetail', params: {id: row.id}});
            },
            sizeChange(val) {
                this.page.currentPage = 1;
                this.page.pageSize = val;
                this.getRecordList();
            },
            currentChange(val) {
                this.page.currentPage = val;
                this.getRecordList();
            },
            searchRecord() {
                this.page.reset();
                this.getRecordList();
                this.isShowSearchRecordDlg = false;
            },
            async getRecordList() {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                this.searchForm.orgId = orgId;
                this.searchForm.limit = this.page.pageSize;
                this.searchForm.offset = (this.page.currentPage - 1) * this.page.pageSize;
                let res = await this.$http.post('/registered/getRegisteredRecordByOrgId', this.searchForm);
                if (res.state) {
                    for (let item of res.data.list) {
                        item.createTime = this.getDateTime(item.createTime);
                    }
                    this.recordList = res.data.list;
                    this.page.total = res.data.totalCount;
                }
            },
            async getDepartment() {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let param = {
                    orgId: orgId,
                    registeredType: 0
                };
                let res = await this.$http.post('/sys/getRegisteredHospitalDep', param);
                if (res.state) {

                    this.registeredDepart = [];
                    if (res.data) {
                        this.registeredDepart = res.data;
                    }
                }
            },
            async handleView(row) {
                console.log(row);
                let str = row.registeredDate;
                let date = str.substring(0,str.length-2);
                let param = {
                    beginDate: this.getDateTime(date),
                    endDate: this.getDateTime(this.getAfterForDate(date,1)),
                    idNumber: row.idNumber,
                    orgId: row.orgId,
                    patientName:row.patientName
                };
                let res = await this.$http.post('/registered/getPatientMedicalRecord',param);
                if(res.state){
                    if(res.data){
                        this.medicalRecord = res.data;
                        this.isShowDetailDlg = true;
                    }
                }
            },
            confirmVisit() {

            },
            refresh() {
                this.page.reset();
                this.searchForm.reset();
                let startTime = this.getDateAddDays(-7);
                let endTime = this.getDate();
                this.dateValue = [startTime, endTime];
                this.searchForm.beginDate = this.dateValue[0];
                this.searchForm.endDate = this.dateValue[1];
                this.getRecordList();
            },
            init() {
                if (this.global.orgId.length) {
                    this.getRecordList();
                    this.getDepartment();
                }else {
                    setTimeout(() => {
                        this.init()
                    }, 200)
                }
            },
        },
        created() {
            let startTime = this.getDateAddDays(-7);
            let endTime = this.getDate();
            this.dateValue = [startTime, endTime];
            this.searchForm.beginDate = this.dateValue[0];
            this.searchForm.endDate = this.dateValue[1];
            this.init()
            /*this.getoptions('registeredStatus');
            this.getoptions('medicalType');*/
        },
        mounted() {
            window.onresize = () => {
                window.fullHeight = document.documentElement.clientHeight;
                this.tableHeight = window.fullHeight - 283;
            }
        }
    }

</script>

<style>
</style>
